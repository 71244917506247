<template>
  <div class="flex flex-col">
    <label v-if="props.label" class="text-sm mb-1 text-black font-medium" v-text="props.label"/>
    <div class="flex flex-row">
      <input ref="inputElement" v-model="childModel" readonly="readonly" type="text" :class="{'px-4 py-2 w-full focus:outline-none focus:text-gray-600 border border-gray-300': !props.avvStyle, 'avv-input-tw': props.avvStyle}" :placeholder="props.placeholder">
      <button v-if="allowClear" class="avv-button w-1/6" role="button" @click="clear">Clear</button>
    </div>
  </div>
</template>
<script lang="ts">
  import {defineComponent, ref, watch} from "vue"
  import flatpickr from "flatpickr";

  export default defineComponent({
    name: "DDatePicker",
    props: {
      label: {
        type: String,
        default: null
      },
      format: {
        type: String
      },
      helpText: {
        type: String,
        default: null
      },
      modelValue: {
        type: String
      },
      placeholder: {
        type: String,
        default: null
      },
      avvStyle: {
        type: Boolean,
        default: false
      },
      allowClear: {
        type: Boolean,
        default: false
      }
    },
    emits: ["update:modelValue"],
    setup(props, ctx) {
      const childModel = ref(props.modelValue)
      const inputElement = ref()
      const flatpickrInstance = ref()
      const allowClear = ref(props.allowClear)

      const clear = () => {
        flatpickrInstance.value?.clear()
      }

      function isValidDate (dateString) {
        if(dateString === "") return true
        // First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
          return false;

        // Parse the date parts to integers
        const parts = dateString.split("/");
        const day = parseInt(parts[1], 10);
        const month = parseInt(parts[0], 10);
        const year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
          return false;

        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
          monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
      }

      watch(childModel, (value) => {
        ctx.emit("update:modelValue", value)
      })

      watch(inputElement, (input) => {
        if(input == null) return;
        if(!isValidDate(inputElement.value.value)) {
          inputElement.value.value = ""
        }

        flatpickrInstance.value = flatpickr(inputElement.value, {
          dateFormat: "Y-m-d",
          altInput: true,
          altFormat: props.format ?? "d/m/Y",
          locale: 'en',
          onClose(selectedDates, dateStr) {
            ctx.emit("update:modelValue", dateStr)
          }
        })

        if(childModel.value) {
          flatpickrInstance.value.setDate(childModel.value, false, props.format)
          childModel.value = inputElement.value.value
        }
      }, { flush: "post" })

      return {
        props,
        inputElement,
        childModel,
        clear,
        allowClear
      }
    }
  });
</script>
